import { gql } from "@apollo/client";

export const GET_ACTIVITY_EVENTS = gql`
  query GetEventsByActivity($activityID: ID!) {
    events(
      sort: "created_at:desc"
      where: { activity: { id: $activityID } }
    ) {
      id
      type
      title
      createdBy {
        id
        firstName
        lastName
        userType
      }
      createdFor {
        id
        firstName
        lastName
      }
      createdDate
      completedDate
      holdType
      description
      dueBy
      dueByMilestone
      milestoneID
      hold {
        id
        expectedCloseDate
        createdInMilestone
        holdDescription
        active
        closeDate
        createdBy {
          id
          firstName
          lastName
        }
        process {
          id
        }
        holdComment
        startDate
        hold_comments {
          id
          comment
          createdAt
          addToTimeline
          createdBy {
            firstName
            lastName
          }
        }
      }
      task {
        id
        title
        milestone
        description
        dueByMilestone
        deadlineAt
        assignments {
          firstName
          lastName
        }
        process {
          id
        }
        task_comments {
          comment
          addToTimeline
          createdAt
          createdBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
