import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { END_CURRENT_PROCESS } from "../../apollo/mutations/endCurrentProcess";
import { CREATE_EVENT } from '../AssetDetail/DetailsSidebar/ProcessDrawer';
import { EVENTS_QUERY } from '../../apollo/queries/eventsQuery';
import { MessageContext } from '../../context/MessageContext';
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import styled from '@emotion/styled';
import { isBankruptcy, isForeclosure } from '../../utilities';
import RequestBillOnClose from './RequestBillOnClose';
import { TASK_FLOWS_QUERY } from '../../apollo/queries/taskFlowsQuery';
import { TASKS_QUERY } from '../../apollo/queries/tasksQuery';

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
`

const getProcessType = (process) => {
  if (isForeclosure(process))
    return "foreclosure"
  else if (isBankruptcy)
    return "bankruptcy"
}

export default function CloseProcess({
  open,
  onClose,
  asset,
  user,
  milestoneID,
  process,
  originalFC,
  setScreen,
  client, 
  setActiveProcess,
  setAsset,
  setHasSentReferral
}) {
  const { addMessage } = useContext(MessageContext);
  const [openRequestBill, setOpenRequestBill] = useState(false);

  const [endProcess] = useMutation(END_CURRENT_PROCESS, {
    refetchQueries: [
      { query: TASK_FLOWS_QUERY, variables: { processID: process?.id } },
      { query: TASKS_QUERY, variables: { processID: process?.id }}
    ],
  });
  const [createEvent] = useMutation(CREATE_EVENT, {
    refetchQueries: "active",
    awaitRefetchQueries: true
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const event = {
      type: "process-closed",
      description: `Process has been closed by ${user.firstName} ${user.lastName}`,
      createdDate: new Date(),
      process: process.id,
      milestoneID
    }
    const originalHold = originalFC && originalFC.holds.filter((hold) => {
      return hold.holdDescription === "BK" && hold.active && !hold.closeDate
    })

    try {
      const resp = await endProcess({
        variables: {
          input: {
            assetID: asset.id,
            processID: process.id,
            closeDate: new Date(),
            originalFC: originalFC?.id,
            originalHold: originalHold?.length ? originalHold[0].id : null
          }
        }
      });

      if (resp && !("errors" in resp)) {
        await createEvent({
          variables: {
            event
          }
        });
  
        if (originalHold?.length) {
          await createEvent({
            variables: {
              event: {
                type: "hold-closed",
                description: `BK Hold has been manually closed`,
                createdDate: new Date(),
                process: originalFC.id,
                milestoneID: originalFC.stepID,
                hold: originalHold[0].id,
                holdType: "Manually Closed BK"
              }
            }
          });
          setScreen(asset.activities.length + 1);
          setActiveProcess(originalFC)
          setAsset((prev) => ({
            ...prev,
            activeProcess: originalFC
          }))
          setHasSentReferral(true)
          await client.refetchQueries({ include: [
            { query: EVENTS_QUERY, variables: { milestoneID: originalFC.stepID, processID: originalFC.id } },
          ] })
        } else {
          setActiveProcess((prev) => ({
            ...prev,
            referral: { deleted: true }
          }))
          setAsset((prev) => ({
            ...prev,
            activeProcess: {
              referral: { deleted: true }
            }
          }))
          setHasSentReferral(false)
        }
        addMessage({ message: "Process closed successfully!" })
        onClose();
      }
    } catch(err) {
      addMessage({ message: "There has been an error closing this process. Please try again."})
    }
  }

  const handleClose = () => {
    onClose();
    setOpenRequestBill(false);
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Typography>
          You&apos;re about to close this {getProcessType(process)} process.
        </Typography>
        <Typography mt={1} mb={5}>
          Are you sure you want to continue?
        </Typography>
        <ButtonBox>
          <Button
            sx={{width: '150px'}}
            onClick={() => setOpenRequestBill(true)}
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
            variant="secondary"
            sx={{
              backgroundColor: 'transparent !important',
              width: 'auto',
              border: 'none',
              boxShadow: 'none',
              marginLeft: '15px',
            }}
          >
            Cancel
          </Button>
        </ButtonBox>
      </Modal>
      <RequestBillOnClose
        open={openRequestBill}
        onClose={handleClose}
        user={user}
        milestoneID={milestoneID}
        originalFC={originalFC}
        closeProcessNow={handleSubmit}
        asset={asset}
        process={process}
        client={client}
      />
    </>
  )
}