import { gql } from "@apollo/client";

export const ASSETS_QUERY = gql`
  query FindAssets($portfolioID: ID) {
    findAssets(portfolioID: $portfolioID) {
      id
      loanNumber
      state
      borough
      statusValue
      upb
      assetAddress
      borrowerFirstName
      borrowerLastName
      servicer

      documents {
        id
      }

      activities {
        id
        comments {
          id
          description
          createdDate
          createdBy {
            firstName
            lastName
          }
        }
        tasks {
          id
          createdAt
          completedAt
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        task_flows {
          id
          startDate
          completedAt
          subtasks {
            id
            completedAt
            task_comments {
            id
            description
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
          }
          task_comments {
            id
            description
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
      }

      activeProcess {
        id
        stepID
        processSteps
        processType

        referral {
          id
          referralStatus
          deleted
          referredBy {
            firstName
            lastName
          }

          team {
            id
            name
          }
        }
        task_flows {
          id
          startDate
          completedAt
          task_comments {
            id
            description
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        tasks {
          id
          createdAt
          completedAt
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        comments {
          id
          description
          createdDate
          createdBy {
            firstName
            lastName
          }
        }
        holds {
          id
          holdDescription
          holdComment
          active
          createdInMilestone
          hold_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
      
      }

      processes {
        id
        stepID
        processSteps

        referral {
          id
          referralStatus
          deleted
          referredBy {
            firstName
            lastName
          }

          team {
            id
            name
          }
        }
        task_flows {
          id
          startDate
          completedAt
          task_comments {
            id
            description
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        tasks {
          id
          createdAt
          completedAt
          task_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
        comments {
          id
          description
          createdDate
          createdBy {
            firstName
            lastName
          }
        }
        holds {
          id
          holdDescription
          holdComment
          active
          createdInMilestone
          hold_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
      }
      loan_status {
        totalDaysInFc
        totalDaysInBk
        stepDaysOnHold
        stepBudgetDays
        totalDaysOverdue
        netVsBudget
        daysInDlq
        budgetDaysToSale
        stepDaysOverdue
        grossVsBudget
        stepGrossFcDays
      }
    }
  }
`;