import { gql } from "@apollo/client";

export const GET_PROCESSES = gql`
  query GetProcesses($assetID: ID!) {
    getAllProcessesFromAsset(assetID: $assetID) {
      id
      deleted
      processType
      stepID
      processSteps
      closeDate
      referral {
        referralStatus
        team {
          name
        }
      }
      tasks {
        id
      }
      holds {
        id
        holdDescription
        startDate
      }
      comments {
        id
      }
      asset {
        statusValue
      }
    }
  }
`;
