import React, { useContext, useRef } from 'react';
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { TEAM_ASSETS_QUERY } from '../../apollo/queries/teamAssetsQuery';
import { UserContext } from '../../context/UserContext';
import GettingStarted from '../GettingStarted/GettingStarted';
import { parseCurrency } from '../../utilities';
import StandardGrid from '../Grid/Grid';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { Box } from '@mui/material';
import StageBars from '../AssetList/StageBars';
import ColoredCell from '../AssetList/ColoredCell';

const ls = window.localStorage;

const columsDef = [
  {
    headerName: "Loan #",
    field: "loanNumber",
    sortable: true,
    filter: true,
    width: 170,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    suppressColumnsToolPanel: true,

  },
  {
    headerName: "Borrower",
    field: "borrowerName",
    sortable: true,
    filter: true,
    width: 150
  },
  {
    headerName: "UPB",
    field: "upb",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    valueFormatter: params => parseCurrency(params?.data?.upb, 2),
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Address",
    field: "assetAddress",
    sortable: true,
    filter: true,
    hide: true,
    width: 250
  },
  {
    headerName: "State",
    field: "state",
    sortable: true,
    filter: true,
    width: 100
  },
  {
    headerName: "Tasks",
    field: "tasks",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 100,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Stage",
    field: "statusValue",
    sortable: true,
    filter: true,
    width: 115,
    cellRenderer: StageBars,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Latest Comment",
    field: "latestComment",
    sortable: true,
    filter: true,
    width: 150,
    hide: true,
  },
  {
    headerName: "Hold Type",
    field: "holdType",
    sortable: true,
    filter: true,
    width: 175,
    hide: true,
  },
  {
    headerName: "Days on Hold",
    field: "daysOnHold",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    hide: true,
  },
  {
    headerName: "Days in FC",
    field: "daysInFC",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Days in BK",
    field: "daysInBK",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    hide: true,
  },
  {
    headerName: "Step Budget Days",
    field: "stepBudgetDays",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Step Days Overdue",
    field: "stepDaysOverdue",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    cellRenderer: ColoredCell,
  },
  {
    headerName: "Total Net Vs Budget",
    field: "netVsBudget",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    cellRenderer: ColoredCell,
  },
  {
    headerName: "Total Gross Vs Budget",
    field: "grossVsBudget",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
    cellRenderer: ColoredCell,
  },
  {
    headerName: "Days in DLQ",
    field: "daysInDlq",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    hide: true,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Budget Days to Sale",
    field: "budgetDaysToSale",
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 150,
    hide: true,
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Assigned Firm",
    field: "servicer",
    sortable: true,
    filter: true,
    width: 200
  },
];

const frameworkComponents = {
  StageBars: StageBars,
  ColoredCell: ColoredCell,
}

export default function TeamAssetList({ team, selectedTab }) {
  const { userType } = useContext(UserContext).user;
  const gridApiRef = useRef(null);

  const [items, setItems] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columsDef)

  const { data: assetData, loading: assetLoading } = useQuery(TEAM_ASSETS_QUERY, {
    skip: !team ? true : false,
    variables: {
      teamID: team?.id,
    },
  })

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    rowSelection: 'single',
    columnDefs,
  };

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  useEffect(() => {
    const visibleColumns = ls.getItem('teamAssetList') ? JSON.parse(ls.getItem('teamAssetList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [selectedTab]);

  useEffect(() => {
    if (assetData) {
      if (team.type === 'EXTERNAL') {
        const transformed = transformAssetData(assetData.getTeamAssets.assets);
        setItems(transformed);
      } else {
        const accepted = assetData.getTeamAssets.assets.filter((asset) => asset.activeProcess?.referral.referralStatus === "ACCEPTED");
        const transformed = transformAssetData(accepted);
        setItems(transformed);
      }
    }

    if (gridApiRef.current) gridApiRef.current.sizeColumnsToFit();
  }, [assetData]);

  if (assetLoading) return <TableSkeleton columnDefs={columsDef} />
  if (!team) return null;

  function onCellClicked() {
    const assetId = gridApiRef.current.getSelectedRows()[0]?.id;
    const win = window.open(`/assets/${assetId}`, "_blank");
    win.focus();
  }

  const onGridSizeChanged = (params) => {
    const cols = params.columnApi.getColumns();
    const numVisible = cols.filter(c => c.visible).length
    if (numVisible <= 11)
      gridApiRef.current.sizeColumnsToFit();
    else
      params.columnApi.autoSizeAllColumns();
  };

  function transformAssetData(assets) {
    if (!assets) return [];

    return assets.map((item) => {
      let currProcess = null;
      if (team.type === 'EXTERNAL')
        currProcess = item?.activeProcess;
      else if (item.activeProcess && item.activeProcess.referral.referralStatus === "ACCEPTED")
        currProcess = item.activeProcess;
      let numActiveTasks = 0;

      const data = { ...currProcess, ...item };

      data.borrowerName = `${item.borrowerFirstName} ${item.borrowerLastName}`.replace("null", "");

      if (currProcess) {
        data.daysInFC = item?.loan_status?.totalDaysInFc === 0 ? '0' : item?.loan_status?.totalDaysInFc || null;
        data.daysInBK = item?.loan_status?.totalDaysInBk === 0 ? '0' : item?.loan_status?.totalDaysInBk || null;
        data.daysOnHold = item?.loan_status?.stepDaysOnHold === 0 ? '0' : item?.loan_status?.stepDaysOnHold || null;
        data.stepBudgetDays = item?.loan_status?.stepBudgetDays === 0 ? '0' : item?.loan_status?.stepBudgetDays || null;
        data.stepDaysOverdue = item?.loan_status?.stepDaysOverdue === 0 ? '0' : item?.loan_status?.stepDaysOverdue || null;
        data.totalDaysOverdue = item?.loan_status?.totalDaysOverdue === 0 ? '0' : item?.loan_status?.totalDaysOverdue || null;
        data.netVsBudget = item?.loan_status?.netVsBudget === 0 ? '0' : item?.loan_status?.netVsBudget || null;
        data.grossVsBudget = item?.loan_status?.grossVsBudget === 0 ? '0' : item?.loan_status?.grossVsBudget || null;
        data.daysInDlq = item?.loan_status?.daysInDlq === 0 ? '0' : item?.loan_status?.daysInDlq || null;
        data.budgetDaysToSale = item?.loan_status?.budgetDaysToSale === 0 ? '0' : item?.loan_status?.budgetDaysToSale || null;
        data.stepDaysOnHold = item?.loan_status?.stepDaysOnHold === 0 ? '0' : item?.loan_status?.stepDaysOnHold || null;
      }

      const totalComments = [];

      // activity data
      if (item.activities.length) {
        const activityComments = item.activities.map((activity) => activity.comments).flat().filter(c => c);

        if (activityComments.length) {
          totalComments.push({
            comment: activityComments[activityComments.length - 1].description,
            date: activityComments[activityComments.length - 1].created_at
          })
        }

        const activityTasks = item.activities.map((activity) => activity.tasks).flat().filter(t => t);
        numActiveTasks += activityTasks.filter((task) => !task.completedAt).length;

        const taskComments = activityTasks.map((task) => task.task_comments).flat().filter((tc) => tc);
        if (taskComments.length) {
          totalComments.push({
            comment: activityComments[activityComments.length - 1].description,
            date: activityComments[activityComments.length - 1].createdAt
          })
        }

        const taskFlows = item.activities.map((activity) => activity.task_flows).flat().filter((tf) => tf);
        numActiveTasks += taskFlows.filter((tf) => !tf.completedAt).length;

        const taskFlowComments = taskFlows.map((tf) => tf.task_comments).flat().filter((tc) => tc);
        if (taskFlowComments.length) {
          totalComments.push({
            comment: taskFlowComments[taskFlowComments.length - 1].description,
            date: taskFlowComments[taskFlowComments.length - 1].createdAt
          })
        }

        if (taskFlows.length) {
          const subtasks = taskFlows.map((tf) => tf.subtasks).flat();
          const subtaskComments = subtasks.map((subtask) => subtask.task_comments).flat().filter((tc) => tc);
          if (subtaskComments.length) {
            totalComments.push({
              comment: subtaskComments[subtaskComments.length - 1].description,
              date: subtaskComments[subtaskComments.length - 1].createdAt
            })
          }
        }
      }

      // process data
      if (currProcess?.comments?.length) {
        totalComments.push({
          comment: currProcess.comments[currProcess.comments.length - 1].description,
          date: currProcess.comments[currProcess.comments.length - 1].createdDate
        })
      }
      if (currProcess?.tasks?.length) {
        numActiveTasks += currProcess.tasks.filter((task) => !task.completedAt).length;

        const hasComments = currProcess.tasks.find((task) => task.task_comments?.length);
        if (hasComments) {
          const tasksWithComments = currProcess.tasks.filter((task) => task.task_comments.length);
          const taskComments = tasksWithComments.map((task) => task.task_comments).flat();

          totalComments.push({
            comment: taskComments[taskComments.length - 1].description,
            date: taskComments[taskComments.length - 1].createdAt
          })
        }
      }
      if (currProcess?.task_flows?.length) {
        numActiveTasks += currProcess.task_flows.filter((tf) => !tf.completedAt).length;

        const hasComments = currProcess.task_flows.find((tf) => tf.task_comments.length);
        if (hasComments) {
          const taskFlowsWithComments = currProcess.task_flows.filter((tf) => tf.task_comments.length);
          const taskFlowComments = taskFlowsWithComments.map((tf) => tf.task_comments).flat();

          totalComments.push({
            comment: taskFlowComments[taskFlowComments.length - 1].description,
            date: taskFlowComments[taskFlowComments.length - 1].createdAt
          })
        }

        // subtasks
        const subtasks = currProcess.task_flows.map((tf) => tf.subtasks).flat();
        const subtaskComments = subtasks.map((subtask) => subtask.task_comments).flat();
        if (subtaskComments.length) {
          totalComments.push({
            comment: subtaskComments[subtaskComments.length - 1].description,
            date: subtaskComments[subtaskComments.length - 1].createdAt
          })
        }
      }
      if (currProcess?.holds?.length) {
        const activeHold = currProcess.holds.find((hold) => hold.active !== false && hold.createdInMilestone === currProcess.statusValue);
        if (activeHold) {
          data.holdReason = activeHold.holdComment;
          data.holdType = activeHold.holdDescription;
        }

        const hasComments = currProcess.holds.find((hold) => hold.hold_comments.length);
        if (hasComments) {
          const holdsWithComments = currProcess.holds.filter((hold) => hold.hold_comments.length);
          const holdComments = holdsWithComments.map((hold) => hold.hold_comments).flat();

          totalComments.push({
            comment: holdComments[holdComments.length - 1].description,
            date: holdComments[holdComments.length - 1].createdAt
          })
        }
      }
      if (totalComments.length) {
        const latest = totalComments.reduce((a, b) => {
          return new Date(a.date) > new Date(b.date) ? a : b;
        });
        data.latestComment = latest.comment;
      }

      data.tasks = numActiveTasks;
      return data;
    });
  }

  const authUsers = (userType === "PORTFOLIO_MANAGER" || userType === "PORTFOLIO_MEMBER")

  if (items.length === 0 && authUsers) {
    return <GettingStarted />
  }
  return (
    <Box sx={{ height: '100%' }}>
      <StandardGrid
        tableName="teamAssetList"
        onGridReady={onGridReady}
        rowData={items}
        onCellClicked={onCellClicked}
        pagination={true}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        frameworkComponents={frameworkComponents}
      />
    </Box>
  );
}